import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["control"]

  initialize() {
    this.element[this.identifier] = this
    this.element.controller = this
  }

  hasValues() {
    return this.controlTargets.some((target) => target.controller.hasValue())
  }

  reset(event) {
    event.preventDefault()
    event.stopPropagation()

    this.controlTargets.forEach((target) => {
      target.controller.resetValue()
    })
  }

  show() {
    this.element.classList.add("filters-panel--active")
  }

  hide() {
    this.element.classList.remove("filters-panel--active")
  }

  change() {
    const event = new CustomEvent("filter-panel:change", {
      bubbles: true,
      cancelable: true,
    })

    this.element.dispatchEvent(event)
  }

  selectedValuesAsText() {
    const selections = []

    this.controlTargets.forEach((target) => {
      const controller = target.controller

      if (controller.hasValue()) {
        selections.push(controller.pillLabel())
      }
    })

    return selections.join(", ")
  }
}
