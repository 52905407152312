import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static targets = ['tab', 'tabIndicator', 'tabIcon', 'clearForm', 'searchInput', 'tooltip']
  static values = {
    activeTabName: String
  }
  static debounces = [
    { name: 'submitForm', wait: 250 }
  ]

  initialize() {
    this.activeTabIndicator = null
    this.activeTab = null
    this.lastOpenedTab = null
    this.filterCounters = {}
    this.shouldResetFiltersOnSubmit = false

    useDebounce(this)
  }

  connect() {
    if (this.activeTabNameValue) {
      this.openTab(this.activeTabNameValue)
      this.tooltipTarget.innerHTML = 'Hide Filters'
    } else {
      this.tooltipTarget.innerHTML = 'Expand Filters'
    }
  }

  get activeFiltersCount() {
    return Object.values(this.filterCounters).reduce((accumulator, value) => {
      return accumulator + value
    }, 0)
  }

  queryChanged() {
    this.shouldResetFiltersOnSubmit = true
  }

  formSubmitted() {
    this.dispatch('submitted')
    if (this.shouldResetFiltersOnSubmit) {
      this.shouldResetFiltersOnSubmit = false
      this.clearFilters()
    }
  }

  tabNav(event) {
    const targetTabName = event.currentTarget.dataset['tabName'] || this.lastOpenedTab || 'moods'
    if (!this.activeTab) {
      return this.openTab(targetTabName)
    }

    if (this.activeTab.dataset.tabName == targetTabName) {
      this.closeActiveTab()
    }
    else {
      this.closeActiveTab()
      this.openTab(targetTabName)
    }
  }

  openTab(name = null) {
    if (name) {
      this.activeTabIndicator = this.tabIndicatorTargets.find(item => item.dataset.tabName == name)
      this.activeTab = this.tabTargets.find(item => item.dataset.tabName == name)
      this.lastOpenedTab = name
      this.activeTabNameValue = name
    }
    this.activeTab.classList.add('filter-active')
    this.activeTabIndicator.classList.add("is-active")
    this.tabIconTarget.classList.add('icon__filter-tab--open')
    this.tooltipTarget.innerHTML = 'Hide Filters'
  }

  closeActiveTab() {
    if(!this.activeTab) return

    this.activeTab.classList.remove('filter-active')
    this.activeTabIndicator.classList.remove('is-active')
    this.tabIconTarget.classList.remove('icon__filter-tab--open')

    this.activeTab = null
    this.activeTabIndicator = null
    this.activeTabNameValue = null
    this.tooltipTarget.innerHTML = 'Expand Filters'
  }

  counterConnected(event) {
    if (event.currentTarget.classList.contains('filter')) {
      this.updateFilterCounterForTab(event.currentTarget)
    } else {
      this.updateFilterCounterForTab(event.currentTarget.closest('.filter'))
    }
  }

  filterChanged() {
    this.updateFilterCounterForTab(this.activeTab)
    this.submitForm()
  }

  submitForm() {
    this.element.closest('form').requestSubmit()
  }

  updateFilterCounterForTab(tab) {
    const controller = tab['filter-counter']
    controller.countActiveFilters()

    this.filterCounters[tab.dataset.tabName] = controller.counter

    const tabIndicator = this.tabIndicatorTargets.find(item => item.dataset.tabName == tab.dataset.tabName)
    controller.counter > 0 ?
      tabIndicator.firstElementChild.innerText = `(${controller.counter})` :
        tabIndicator.firstElementChild.innerText = ''

    this.updateClearFormState()
  }

  updateClearFormState() {
    if (this.activeFiltersCount > 0) {
      this.clearFormTarget.classList.add('clear-form-active')
    } else {
      this.clearFormTarget.classList.remove('clear-form-active')
    }
  }

  clearFilters() {
    this.dispatch('cleared', { cancelable: false })
    this.resetCheckboxes()
    this.closeActiveTab()
    this.clearTabsIndicators()
    this.clearTabs()
    this.clearFormTarget.classList.remove('clear-form-active')
  }

  resetCheckboxes() {
    this.element
      .closest('form')
      .querySelectorAll('input[type=checkbox], input[type=radio]')
      .forEach(input => input.checked = false)
  }

  clearTabsIndicators() {
    Array.from(this.tabIndicatorTargets).forEach((tab) => {
      tab.firstElementChild.innerHTML = ''
      tab.classList.remove('is-active')
    })
  }

  clearTabs() {
    Array.from(this.tabTargets).forEach((tab) => {
      tab.classList.remove('filter-active')
    })
    this.tabIconTarget.classList.remove('icon__filter-tab--open')
  }

  clearSearchInput() {
    this.searchInputTarget.value = null
    this.submitForm()
  }
}
