import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    successStatus: { type: Number, default: 200 },
    pendingStatus: { type: Number, default: 202 },
    maxRetries: { type: Number, default: 10 },
    successUrl: String,
    statusUrl: String,
  }

  initialize() {
    this.poll = this.poll.bind(this)
    this.handleError = this.handleError.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.scheduleAnotherTry = this.scheduleAnotherTry.bind(this)
    this.retries = 0
  }

  connect() {
    this.poll()
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  async poll() {
    const response = await get(this.statusUrlValue)
    this.handleSuccess(response)
  }

  handleError() {
    console.log("error")
  }

  handleSuccess(response) {
    if (response.statusCode === this.successStatusValue) {
      this.handleSuccessResponse(response)
    } else if (response.statusCode === this.pendingStatusValue) {
      this.scheduleAnotherTry()
    } else {
      this.handleError()
    }
  }

  async handleSuccessResponse(force = false) {
    if (this.successUrlValue) {
      const url = new URL(this.successUrlValue)
      if (force) {
        url.searchParams.set("force", "true")
      }
      await get(url, { responseKind: "turbo-stream" })
    }
  }

  scheduleAnotherTry() {
    this.retries++
    if (this.retries < this.maxRetriesValue) {
      this.timeout = setTimeout(this.poll, 1000)
    } else {
      this.handleSuccessResponse(true)
    }
  }
}
