import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['currentShape']

  select(event) {
    event.preventDefault()
    this.currentShapeTarget.checked = !this.currentShapeTarget.checked
  }
}
