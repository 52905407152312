import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.stickinessOvserver = new IntersectionObserver(
      ([el]) => el.target.classList.toggle("is-stuck", el.intersectionRatio < 1),
      { threshold: [1] }
    );
    this.stickinessOvserver.observe(this.element);
  }

  disconnect() {
    this.stickinessOvserver.disconnect();
  }
}
