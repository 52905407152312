import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static classes = ['toggle']
  static targets = ['outsideElement']
  static values = {
    outside: { type: Boolean, default: false }
  }

  toggle() {
    this.element.classList.toggle(this.toggleClass)
  }

  connect() {
    if (this.hasOutsideElementTarget) { useClickOutside(this, { element: this.outsideElementTarget }) }
  }

  clickOutside() {
    let videoPlayer = document.getElementsByClassName('modal-play-video__player')[0]
    if (videoPlayer) { videoPlayer.contentWindow.postMessage('{"method":"pause"}', '*')  }

    if (this.outsideValue == true) { this.toggle() }
  }
}
