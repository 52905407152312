import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (!window.localStorage.getItem('skip-mobile-overlay')) {
      this.element.classList.remove('is-hidden')
    }
  }

  skip() {
    window.localStorage.setItem('skip-mobile-overlay', true)
    this.element.classList.add('is-hidden')
  }
}
