import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs"

export default class extends Controller {
  static targets = ['input', 'dropArea', 'cropperModal', 'cropperImage']
  static values = {
    imagePath: String,
    allowedExtensions: String
  }

  connect() {
    if (this.allowedExtensionsValue) {
      this.inputTarget.accept = this.allowedExtensionsValue.split(' ').join(',')
    }
    this.presetImageWhenAvailable()
    this.cropper = new Cropper(this.cropperImageTarget, {
      aspectRatio: 1,
      dragMode: 'move',
    })
  }

  openFileSelect() {
    this.inputTarget.click()
  }

  inputImageURL() {
    const [file] = this.inputTarget.files
    if (file && file.type.length > 1 && this.allowedExtensionsValue.includes(file.type)) {
      this.hideValidationError()
      return URL.createObjectURL(file)
    } else {
      this.showValidationError()
      return null
    }
  }

  showValidationError() {
    this.element.classList.add('image-uploader--invalid')
  }

  hideValidationError() {
    this.element.classList.remove('image-uploader--invalid')
  }

  fileSelected() {
    const file = this.inputImageURL()
    if (file) {
      this.cropper.replace(file)
      this.cropperModalTarget.classList.add('is-active')
    }
  }

  cancelCrop() {
    this.cropperModalTarget.classList.remove('is-active')
  }

  onDrop(e) {
    e.preventDefault()

    this.dropAreaTarget.classList.remove('image-uploader__drop-area--waiting')
    let dt = e.dataTransfer
    let files = dt.files
    this.inputTarget.files = files;
    this.fileSelected()
  }

  onDragOver(e) {
    e.preventDefault()

    this.dropAreaTarget.classList.add('image-uploader__drop-area--waiting')
  }

  onDragLeave() {
    this.dropAreaTarget.classList.remove('image-uploader__drop-area--waiting')
  }

  applyCrop() {
    this.cropper.getCroppedCanvas().toBlob((blob) => {
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg', lastModified:new Date().getTime() });
      const dt = new DataTransfer()
      dt.items.add(file)
      this.inputTarget.files = dt.files
      this.updateImagePreview(URL.createObjectURL(file))
      this.cropperModalTarget.classList.remove('is-active')
    }, 'image/jpeg', 0.95)
  }

  updateImagePreview(imageUrl) {
    this.dropAreaTarget.style = `background-image: url(${imageUrl});`
    this.dropAreaTarget.classList.add('image-uploader__drop-area--with-image')
  }

  presetImageWhenAvailable() {
    if (this.inputTarget.files.length > 0) {
      const file = this.inputImageURL()
      if (file) {
        this.updateImagePreview(file)
      }
    } else if (this.imagePathValue) {
      this.updateImagePreview(this.imagePathValue)
    }
  }
}
