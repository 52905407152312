import { Controller } from '@hotwired/stimulus'
import iro from '@jaames/iro'

export default class extends Controller {
  static targets = ['moodWheel', 'moodWheelTitle', 'selectedMoods', 'moodBadgeTemplate', 'addMoodBtn', 'suggestions']
  static values = {
    initial: Array
  }

  initialize() {
    this.element.controller = this
    this.allMoods = window.moods

    this.targetedMood = null
    this.selectedMoods = []
    this.onColorChange = this.onColorChange.bind(this)
  }

  connect() {
    if(this.moodWheelTarget.children.length != 0) {
      this.moodWheelTarget.children[0].remove()
    }
    
    this.moodWheel = new iro.ColorPicker(this.moodWheelTarget, {
      width: 180,
      layout: [
        {
          component: iro.ui.Wheel,
        }
      ],
      colors: ['#808080'],
      wheelLightness: false,
      wheelAngle: 210,
      wheelDirection: 'clockwise',
    })
    this.moodWheel.on('color:change', this.onColorChange)

    if (this.hasInitialValue) {
      this.selectedMoods = this.initialValue.map((id) => this.allMoods.find((mood) => mood.id === id))
      this.renderBadges()
    }

    if (this.selectedMoods.length === 0) {
      this.toggleSuggestions()
    }

    this.dispatch('connected')
  }

  disconnect() {
    this.moodWheel.off('color:change', this.onColorChange)
    delete this.moodWheel
    this.moodWheelTarget.innerHTML = ''
  }

  resetMoods() {
    this.selectedMoods = []
    this.renderBadges()
  }

  addMood() {
    if (this.targetedMood && this.selectedMoods.length < 4) {
      if (!this.selectedMoods.find((mood) => mood.id === this.targetedMood.id)) {
        this.selectedMoods.push(this.targetedMood)
        this.selectedMoodsTarget.append(this.buildMoodBadge(this.targetedMood.id))
        this.updateAddBtnState()
        this.suggestionsTarget.classList.remove('filter-moods__suggestions--expanded')
        this.dispatch('changed')
      }
    }
  }

  removeMood(e) {
    this.targetMoodId = parseInt(e.currentTarget.querySelector('input').value)
    this.selectedMoods.splice(this.selectedMoods.indexOf((mood) => mood.id === this.targetMoodId), 1)
    e.currentTarget.remove()
    this.updateAddBtnState()
    this.dispatch('changed')
  }

  selectPalette(e) {
    const ids = JSON.parse(e.currentTarget.dataset.moodIds)
    this.selectedMoods = ids.map((id) => this.allMoods.find((mood) => mood.id === id))
    this.renderBadges()
    this.dispatch('changed')
  }

  renderBadges() {
    this.element.querySelectorAll('.selected-mood-badge').forEach((el) => el.remove())
    this.selectedMoods.forEach((el) => {
      this.selectedMoodsTarget.prepend(this.buildMoodBadge(el.id))
    })
    this.updateAddBtnState()
  }

  onColorChange(color) {
    this.targetedMood = this.closestMood(color.hsl)
    this.moodWheelTitleTarget.innerText = this.targetedMood.title
  }

  closestMood(selectedColor) {
    return this.allMoods.reduce((prev, curr) => {
      return (
        this.calculateMoodRanking(curr, selectedColor) < this.calculateMoodRanking(prev, selectedColor) ?
          curr : prev
      )
    })
  }

  updateAddBtnState() {
    if (this.selectedMoods.length < 4) {
      this.addMoodBtnTarget.classList.remove('is-hidden')
    } else {
      this.addMoodBtnTarget.classList.add('is-hidden')
    }
  }

  calculateMoodRanking(mood, selectedColor) {
    return Math.pow(mood.h - selectedColor.h, 2) + Math.pow(mood.s - selectedColor.s, 2)
  }

  buildMoodBadge(moodId) {
    const selectedMood = this.allMoods.find((mood) => mood.id === moodId)
    const node = this.moodBadgeTemplateTarget.cloneNode(true)
    const colorNode = node.querySelector('.mood-badge__color')
    const titleNode = node.querySelector('.mood-badge__title')
    const inputNode = node.querySelector('input')

    colorNode.style = `background-color: hsl(${selectedMood.h}, ${selectedMood.s}%, 50%)`
    titleNode.innerText = selectedMood.title
    inputNode.value = selectedMood.id
    inputNode.checked = true

    node.classList.remove('is-hidden')
    node.classList.add('selected-mood-badge')

    return node
  }

  toggleSuggestions() {
    this.suggestionsTarget.classList.toggle('filter-moods__suggestions--expanded')
  }
}
