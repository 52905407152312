import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
   connect() {
      console.log("connected");
      window.addEventListener("popstate", this.handleBackButton.bind(this));
   }

   disconnect() {
      window.removeEventListener("popstate", this.handleBackButton.bind(this));
   }

   handleBackButton() {
      if (window.location.pathname === '/collections') {
         window.location.reload()
      }
   }
}
