import { find } from "lodash"

export const closestMood = (moods, hsl) => {
  return moods
    .map((mood) => {
      return {
        title: mood.title,
        distanceSq: Math.pow(mood.h - hsl.h, 2) + Math.pow(mood.s - hsl.s, 2),
        source: mood,
      }
    })
    .sort((a, b) => a.distanceSq - b.distanceSq)[0]
}

export const moodByTitle = (moods, title) =>
  find(moods, (mood) => mood.title === title)
