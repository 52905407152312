import { Controller } from "@hotwired/stimulus";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import TWEEN from "@tweenjs/tween.js";
import throttle from "lodash/throttle";
import iro from "@jaames/iro";
import { closestMood } from "../lib/mood";
import { useWindowResize } from "stimulus-use";
import {
  BloomEffect,
  EffectComposer,
  EffectPass,
  RenderPass,
} from "postprocessing";

export default class extends Controller {
  static targets = [
    "render",
    "label",
    "stepDescription",
    "nextLabel",
    "currentLabel",
    "moodWheelForm",
  ];

  static values = {
    rendererIsDisplayed: Boolean,
    bloomEffectLightenLevel: { type: Number, default: 6 },
  };

  initialize() {
    this.element[this.identifier] = this;
    this.element.controller = this;
    this.animate = this.animate.bind(this);
    this.start = this.start.bind(this);
    this.prevMood = null;
    this.handleMood = throttle(this.handleMood.bind(this), 80);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.iro = new iro.Color();
    this.materials = [];
  }

  handleWindowResize() {
    const width = this.expectedWidth;
    const height = this.expectedWidth;

    const aspect = width / height;

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.renderTex.setSize(width * 0.25, height * 0.25);

    this.camera.aspect = aspect;
    this.camera.updateProjectionMatrix();

    if (this.controls.enabled) {
      this.controls.handleResize();
    }
  }

  hexToHSL(hex) {
    this.iro.hexString = hex;
    return this.iro.hsl;
  }

  resizeScene() {
    this.expectedWidth = this.moodWheelFormTarget.clientWidth;
    this.expectedHeight = this.moodWheelFormTarget.clientWidth;

    this.renderer.setSize(this.expectedWidth, this.expectedHeight);
  }

  createScene() {
    this.expectedWidth = this.moodWheelFormTarget.clientWidth;
    this.expectedHeight = this.moodWheelFormTarget.clientWidth;
    const aspect = this.expectedWidth / this.expectedHeight;
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(10, aspect, 0.1, 1000);
  }

  setInitialCameraPosition() {
    this.camera.position.z = 15;
    this.camera.position.y = 0;
    this.camera.position.x = 0;
    this.camera.fov = 45;
  }

  createControls() {
    this.controls = new TrackballControls(
      this.camera,
      this.renderer.domElement
    );
    this.controls.rotateSpeed = 3.0;
    this.controls.zoomSpeed = 0.0;
    this.controls.panSpeed = 0.0;
    this.controls.enabled = false;
    this.controls.noZoom = true;
    this.controls.addEventListener("change", () => this.handleControlChange());
  }

  start() {
    this.controls.enabled = true;
    this.camera.updateProjectionMatrix();
  }

  nextText(text) {
    const time = 80;
    let style = { top: 50, opacity: 1 };

    new TWEEN.Tween(style)
      .to({ top: 47, opacity: 0 }, time)
      .start()
      .onUpdate(() => {
        this.currentLabelTarget.style.top = `${style.top}%`;
        this.currentLabelTarget.style.opacity = style.opacity;
      });

    this.nextLabelTarget.innerHTML = text;
    let nStyle = { top: 53, opacity: 0 };
    new TWEEN.Tween(nStyle)
      .to({ top: 50, opacity: 1 }, time)
      .start()
      .onUpdate(() => {
        this.nextLabelTarget.style.top = `${nStyle.top}%`;
        this.nextLabelTarget.style.opacity = nStyle.opacity;
      })
      .onComplete(() => {
        this.currentLabelTarget.style.top = "50%";
        this.currentLabelTarget.style.opacity = 1;
        this.currentLabelTarget.innerHTML = text;
        this.nextLabelTarget.style.top = "53%";
        this.nextLabelTarget.style.opacity = 0;
      });
  }

  addShapes() {
    const sphereTex = new THREE.TextureLoader().load(SphereImages["gradient"],);
    const geometry = new THREE.SphereGeometry(3, 60, 60);

    const mesh = new THREE.MeshBasicMaterial({
      color: 0xcccccc,
      map: sphereTex,
    });

    const sphere = new THREE.Mesh(geometry, mesh);
    this.scene.add(sphere);
  }

  handleControlChange() {
    // this is to delay the setting of the first mood to show
    // the "every mood..." copy
    if (this.showForm) {
      this.renderer.setRenderTarget(this.renderTex);
      this.renderer.clear();
      this.renderer.render(this.scene, this.camera);

      const rb = new Float32Array(4);
      this.renderer.readRenderTargetPixels(
        this.renderTex,
        this.renderTex.width * 0.5,
        this.renderTex.height * 0.5,
        1,
        1,
        rb
      );

      this.renderer.setRenderTarget(null);
      this.renderer.clear();
      this.renderer.render(this.scene, this.camera);

      const targetCol = new THREE.Color(rb[0], rb[1], rb[2]);
      const hsl = this.hexToHSL(`#${targetCol.getHexString()}`);

      const event = new CustomEvent("background-color:set", {
        detail: { from: hsl, to: hsl },
      });

      document.dispatchEvent(event);
      this.handleMood(hsl);
    }

    this.showForm = true;
  }

  handleMood(hsl) {
    const nextMood = closestMood(window.moods, hsl);
    if (this.prevMoodString !== nextMood.title) {
      this.nextText(nextMood.title);
      this.currentMood = nextMood.source;

      const changeEvent = new CustomEvent("mood-form:mood-change", {
        detail: nextMood.source,
      });

      document.dispatchEvent(changeEvent);

      this.prevMoodString = nextMood.title;
    }
  }

  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: false });
    this.renderer.setSize(this.expectedWidth, this.expectedHeight);

    if (this.rendererIsDisplayedValue == false) {
      this.renderTarget.appendChild(this.renderer.domElement);
    } else {
      this.renderTarget.childNodes[0].remove();
      this.renderTarget.appendChild(this.renderer.domElement);
    }
    this.renderTex = new THREE.WebGLRenderTarget(
      this.expectedWidth,
      this.expectedHeight,
      {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        type: THREE.FloatType,
      }
    );

    this.materials.push(this.renderTex);
  }

  createEffectComposer() {
    if (this.moodWheelFormTarget.clientWidth <= 800) {
      this.bloomEffectLightenLevelValue = 5;
    }
    this.composerNew = new EffectComposer(this.renderer, {
      multisampling: Math.min(4, this.renderer.capabilities.maxSamples),
    });
    this.composerNew.addPass(new RenderPass(this.scene, this.camera));
    this.composerNew.addPass(
      new EffectPass(
        this.scene,
        new BloomEffect({
          blendFunction: THREE.MULTIPLY,
          luminanceThreshold: 0.001,
          intensity: 1.2,
          radius: 0.7,
          luminanceSmoothing: 0.01,
          opacity: 0.9,
          mipmapBlur: true,
          levels: this.bloomEffectLightenLevelValue,
        })
      )
    );
  }

  animate() {
    this.animationFrame = requestAnimationFrame(this.animate);
    TWEEN.update();
    if (this.controls.enabled) {
      this.controls.update();
    }

    this.prevCamPos = this.camera.position;
    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);
    this.composerNew.render();
  }

  connect() {
    useWindowResize(this);
    window.addEventListener("resize", this.handleWindowResize, false);
    this.materials = [];
    this.createScene();
    this.createRenderer();
    this.createEffectComposer();
    this.createControls();
    this.addShapes();
    this.setInitialCameraPosition();
    this.animate();
    this.start();
  }

  disconnect() {
    window.removeEventListener("resize", this.handleWindowResize, false);
    cancelAnimationFrame(this.animationFrame);
    this.materials.forEach((obj) => obj.dispose());
    this.materials = [];
    this.scene.clear();
    this.rendererIsDisplayedValue = true;
    this.showForm = false;
  }
}
