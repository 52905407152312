// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails"
import "./../channels"

import Rails from "./../lib/rails-ujs"
Rails.start()

import $ from 'jquery';
window.$ = $;
import "./../controllers"
import "./../lib/turbo_improvements"

document.addEventListener("turbo:before-cache", function() {
  // Animations cleanup - so they don't rerun when cache loaded
  const animatedElements = document.querySelectorAll('.animate__animated')
  animatedElements.forEach((el) => el.classList.remove('animate__animated'))
  // Remove all modals so they don't reappear
  const modals = document.querySelectorAll('.modal')
  modals.forEach((el) => el.remove())
})
