import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
   static targets = ["cover", "playButton"];

   connect() {
      this.element.addEventListener( "mouseleave", this.handleMouseLeave.bind(this));
      this.element.addEventListener( "mouseenter", this.handleMouseEnter.bind(this));
   }

   handleMouseEnter() {
      this.coverTarget.classList.add("hovered-cover-image");
      this.playButtonTarget.classList.add('hovered-player-btn');
   }

   handleMouseLeave() {
      this.coverTarget.classList.remove("hovered-cover-image");
      this.playButtonTarget.classList.remove('hovered-player-btn');
   }
}
