import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['selectInputPlaceholder', 'selectInputValue', 'options', 'option']

  connect() {
    useClickOutside(this);
  }

  activateInput() {
    if (this.optionTargets.length > 0) {
      this.selectInputPlaceholderTarget.parentElement.classList.toggle('is-active')
      this.optionsTarget.classList.toggle('is-active')
    }
  }

  selectProject(event) {
    this.selectInputValueTarget.value = event.params.projectId
    this.selectInputPlaceholderTarget.innerText = event.params.projectTitle
    this.selectInputPlaceholderTarget.parentElement.childNodes[1].outerHTML = event.currentTarget.childNodes[1].outerHTML
    this.clickOutside()
  }

  clickOutside() {
    this.selectInputPlaceholderTarget.parentElement.classList.remove('is-active')
    this.optionsTarget.classList.remove('is-active')
  }
}
