import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    remainingDownloads: Number,
    downloadedRecordings: Array(Number),
    allowedToRequestMoreDownloads: Boolean,
    activeProjectId: Number,
    activeProjectRecordings: Array(Number),
    activeProjectEditable: Boolean,
  }

  connect () {
    this.element[this.identifier] = this
    this.dispatch('recordings-updated')
  }

  get userRemainingDownloads() {
    return this.remainingDownloadsValue
  }

  get userDownloadedRecordings() {
    return this.downloadedRecordingsValue
  }

  get userAllowedToRequestMoreDownloads() {
    return this.allowedToRequestMoreDownloadsValue
  }

  get activeProjectId() {
    return this.activeProjectIdValue
  }

  get activeProjectRecordings() {
    return this.activeProjectRecordingsValue
  }

  get activeProjectEditable() {
    return this.activeProjectEditableValue
  }
}
