import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    counter: { type: Number, default: 0 }
  }

  connect () {
    this.element[this.identifier] = this
    this.dispatch('connected')
  }

  get counter() {
    return this.counterValue
  }

  countActiveFilters() {
    this.counterValue = 0
    Array.from(this.element.querySelectorAll('input')).forEach((filter) => {
      if (filter.type == 'range' && filter.value != 50) {
        this.counterValue++
      } else if (filter.checked) {
        this.counterValue++
      }
    })
  }
}
