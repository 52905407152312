import Autocomplete from 'stimulus-autocomplete'
import { get } from "@rails/request.js"

export default class extends Autocomplete {
  static targets = ['loading']
  static values = {
    resultId: { type: String, default: '' },
  }

  onInputChange = () => {
    const spotifyActive = !!document.querySelector('.search-filter__spotify.is-active')
    if (this.hasHiddenTarget) this.hiddenTarget.value = ""

    const query = this.inputTarget.value.trim()
    if (query && query.length >= this.minLengthValue && spotifyActive) {
      this.fetchResults(query)
    }
  }

  buildURL(query) {
    return `${new URL(this.urlValue, window.location.href).toString()}/${query}`
  }

  fetchResults = async (query) => {
    if (!this.hasUrlValue) return

    try {
      this.element.dispatchEvent(new CustomEvent("loadstart"))
      this.startLoadingAnimation()
      const html = await get(this.buildURL(query), { responseKind: "turbo-stream" })
      this.replaceResults(html)
      this.stopLoadingAnimation()
      document.getElementsByClassName('search-bar__filters')[0].classList.add('is-active')
      this.element.dispatchEvent(new CustomEvent("load"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
    } catch(error) {
      this.stopLoadingAnimation()
      this.element.dispatchEvent(new CustomEvent("error"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
      throw error
    }
  }

  startLoadingAnimation() {
    this.isLoading = true
    this.showLoadingAnimation = setTimeout(() => { this.loadingTarget.classList.add('is-loading') }, 200)
  }

  stopLoadingAnimation() {
    this.isLoading = false
    if (this.showLoadingAnimation) {
      clearTimeout(this.showLoadingAnimation)
    }
    this.loadingTarget.classList.remove('is-loading')
  }

  onEnterKeydown = (event) => {
    const firstResult = document.querySelector('[data-result-id]')
    const spotifyActive = !!document.querySelector('.search-filter__spotify.is-active')

    if (spotifyActive && this.hiddenTarget.value == '' && this.resultsShown) {
      this.commit(firstResult)
    } else if (spotifyActive && this.hiddenTarget.value != '') {
      this.commit(this.hiddenTarget.value)
    } else if (spotifyActive && !this.resultsShown) {
      event.preventDefault()
    }
  }

  onEscapeKeydown = (event) => {
    event.preventDefault()
  }

  onResultsClick = (event) => {
    if (!(event.target instanceof Element)) return
    this.commit(event.target)
  }

  commit(selected) {
    if (selected instanceof Element) {
      this.inputTarget.value = selected.innerText
      this.hiddenTarget.value = selected.dataset['resultId']
      this.dispatch('selected')
    } else {
      return this.hiddenTarget.value
    }
  }
}
