import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'
import Scrollparent from 'scrollparent';

export default class extends Controller {
  static targets = ["wrapper", "menu"]
  static values = { 
    isDynamic: { type: Boolean, default: true }, 
    isSidebar: { type: Boolean, default: false }
  }

  connect() {
    useClickOutside(this);
  }

  toggle(event) {
    if (this.isDynamicValue) {
      this.wrapperTarget.classList.toggle('is-active');

      const targetLocation = this.wrapperTarget.getBoundingClientRect()
      const scrollableParent = Scrollparent(this.wrapperTarget).getBoundingClientRect()
      const dropdownMenuSize = this.wrapperTarget.querySelector('.dropdown-menu').getBoundingClientRect()
      const eventTopPosition = event.target.getBoundingClientRect().y

      //
      // if menu is close to the bottom of the parent
      if (targetLocation.bottom + dropdownMenuSize.height < scrollableParent.bottom + 10) {
        this.isSidebarValue ? this.menuTarget.style.top = `${eventTopPosition}px` : this.wrapperTarget.classList.remove('is-up')
      } else {
        this.isSidebarValue ?
          this.menuTarget.style.top = `${eventTopPosition - dropdownMenuSize.height}px` :
            this.wrapperTarget.classList.add('is-up')
      }
      // if menu is close to the right side of the parent
      if (targetLocation.right + dropdownMenuSize.width > scrollableParent.right) {
        this.wrapperTarget.classList.add('is-right')
      } else {
        this.wrapperTarget.classList.remove('is-right')
      }
    } else {
      this.wrapperTarget.classList.toggle('is-active')
    }
  }

  clickOutside() {
    this.wrapperTarget.classList.remove('is-active');
  }
}
