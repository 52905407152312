import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    recordingId: Number
  }

  async download(event) {
    event.preventDefault()
    let downloadUrl = `/compositions/${this.recordingIdValue}/download`
    window.open(downloadUrl, '_blank');
    const replaceRemainingDownlaodUrl = downloadUrl.replace('download', 'update_after_download')
    await get(replaceRemainingDownlaodUrl, { responseKind: "turbo-stream" })
  }
}
