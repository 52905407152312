import { html } from "htm/preact"

import { autocomplete } from "@algolia/autocomplete-js"
import axios from "axios"
import { Controller } from "@hotwired/stimulus"
import { first } from "lodash"

const ENDPOINTS = {
  LIBRARY: "LIBRARY",
  SPOTIFY: "SPOTIFY",
}

const DEFAULT_ENDPOINT = ENDPOINTS.LIBRARY

const buildRequestURI = (endpoint, query) =>
  ({
    [ENDPOINTS.LIBRARY]: (query) => {
      return [`/recordings/search/${query}`]
    },
    [ENDPOINTS.SPOTIFY]: (query) => {
      return [`/spotify/search/${query}`]
    },
  }[endpoint](query))

const libraryItem = (item) => {
  return html`<a
    data-id="${item.attributes.id}"
    data-turbo-frame="results"
    href="/similarity/track/library/${item.attributes.cyanite_id}"
    >${item.attributes.recording_title}</a
  >`
}

const spotifyItem = (item) => {
  return html`<a
    data-turbo-frame="results"
    href="/similarity/track/spotify/${item.id}"
    >${item.name} - ${first(item.artists).name}</a
  >`
}

const instantiate = (target, endpoint) =>
  autocomplete({
    debug: true,
    container: target,
    placeholder: "Search for tracks...",
    onStateChange({ state }) {},
    getSources({ query }) {
      const requestURI = buildRequestURI(endpoint, query)
      return axios.get(...requestURI).then((res) => {
        const items =
          endpoint === ENDPOINTS.LIBRARY ? res.data.data : res.data.tracks.items
        return [
          {
            sourceId: "recordings",
            getItems: () => items,
            getItemInputValue: ({ item }) =>
              endpoint === ENDPOINTS.LIBRARY
                ? item.attributes.recording_title
                : `${item.name} - ${first(item.artists).name}`,
            templates: {
              item: ({ item }) =>
                endpoint === ENDPOINTS.LIBRARY
                  ? libraryItem(item)
                  : spotifyItem(item),
              noResults: () => "No results.",
            },
          },
        ]
      })
    },
  })

export default class extends Controller {
  static targets = ["searchIntegration", "endpoint", "endpointSelect"]

  connect() {
    this.autocompleteTarget = `#${this.searchIntegrationTarget.id}`
    this.autocomplete = this.instantiateWithEndpoint(DEFAULT_ENDPOINT)
    this.highlightActiveEndpointSelect(DEFAULT_ENDPOINT)
  }

  highlightActiveEndpointSelect(endpoint) {
    this.endpointTargets.forEach((target) => {
      if (endpoint === target.dataset.endpoint) {
        target.classList.add("active")
      } else {
        target.classList.remove("active")
      }
    })
  }

  instantiateWithEndpoint(endpoint) {
    return instantiate(this.autocompleteTarget, endpoint)
  }

  selectEndpoint(event) {
    const endpoint = event.target.dataset.endpoint
    this.highlightActiveEndpointSelect(endpoint)
    this.autocomplete.destroy()
    this.autocomplete = this.instantiateWithEndpoint(endpoint)
  }
}
