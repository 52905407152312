import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ["trigger", "selectAllOption", "option", "options", "singleSelect"]
  static values = {
    prompt: String,
    singleItem: String,
    multipleItems: String
  }

  connect() {
    useClickOutside(this)
    if (this.optionTargets.length != 0) {
      this.updateCaption()
    }
  }

  toggleOptions() {
    if (this.optionTargets.length != 0) {
      this.triggerTarget.classList.toggle('is-active')
      this.optionsTarget.classList.toggle('is-active')
    }
  }

  clickOutside() {
    this.triggerTarget.classList.remove('is-active')
    this.optionsTarget.classList.remove('is-active')
  }

  optionChanged(event) {
    if (this.hasSingleSelectTarget) {
      if (event.currentTarget === this.singleSelectTarget) {
        this.deselectOtherOptions(event)
      } else {
        this.singleSelectTarget.checked = false
      }
    }
    this.updateCaption()
  }

  updateCaption() {
    let selectedOptionsCounter = this.optionTargets.filter(option => option.checked).length
    if (this.hasSingleSelectTarget && this.singleSelectTarget.checked) {
      selectedOptionsCounter++
    }

    if (selectedOptionsCounter === 0) {
      this.triggerTarget.innerText = this.promptValue
    } else {
      const itemTranslation = selectedOptionsCounter === 1 ? this.singleItemValue : this.multipleItemsValue
      this.triggerTarget.innerText = `${selectedOptionsCounter} ${itemTranslation} selected`
    }
  }

  deselectOtherOptions(event) {
    if (event.currentTarget.checked) {
      this.optionTargets.filter(option => option != event.currentTarget).forEach(option => option.checked = false)
    }
  }

  selectAllOptions() {
    this.optionTargets.forEach(option => option.checked = true)
    this.updateCaption()
  }
}
