import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static targets = ["input"]
  static values = {
    url: String,
    formName: String,
    minimumParamsSize: { type: Number, default: 3 }
  }
  static debounces = ["makeRequest", "prepareParams"]

  connect() {
    useDebounce(this)
    this.prepareParams()
  }

  makeRequest() {
    document.getElementById('pricing').setAttribute('busy', true)
    get(`${this.urlValue}?${this.convertSelectedOptions()}`, { responseKind: "turbo-stream" })
  }

  handleChange() {
    this.prepareParams()
  }

  prepareParams() {
    this.selectedOptions = {}
    this.inputTargets.forEach((input) => this.checkValue(input))
    this.checkIfRequestPossible()
  }

  checkIfRequestPossible() {
    if (Object.keys(this.selectedOptions).length >= this.minimumParamsSizeValue) {
      this.makeRequest()
    }
  }

  convertSelectedOptions() {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(this.selectedOptions)) {
      if (Array.isArray(value)) {
        value.forEach((v) => params.append(`${this.formNameValue}[${key}][]`, v));
      } else {
        params.set(`${this.formNameValue}[${key}]`, value);
      }
    }
    return params.toString();
  }

  checkValue(input) {
    if (input.type === 'checkbox' && !input.checked) {
      return
    }
    if (input.value.length === 0) {
      return
    }

    const isArrayValue = input.name.endsWith('[]')
    const inputName = input.name.match(/\[(.*?)\]/)[1]

    if (isArrayValue) {
      if (this.selectedOptions[inputName]) {
        this.selectedOptions[inputName].push(input.value)
      } else {
        this.selectedOptions[inputName] = [input.value]
      }
    } else {
      this.selectedOptions[inputName] = input.value
    }
  }
}
