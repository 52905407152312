import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["player", "playButtonOverlay"]

  playVideo() {
    this.playerTarget.setAttribute("allow", "autoplay")
    let newSrc = new URL(this.playerTarget.src.split('&showinfo=0&controls=0'))
    newSrc.searchParams.set("autoplay", 1)
    this.playerTarget.src = newSrc.href
    this.playButtonOverlayTarget.classList.add('player-overlay--is-active')
  }
}
