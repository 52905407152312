import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['burgerMenu', 'burgerIcon', 'navbar']

  changeBackground() {
    if (window.scrollY <= 50 && !this.burgerMenuTarget.classList.contains('is-active')) {
      this.navbarTarget.classList.remove('navbar-blackout')
    } else {
      this.navbarTarget.classList.add('navbar-blackout')
    }
  }

  toggle() {
    this.toggleMenu(this.burgerIconTarget)
    this.toggleMenu(this.burgerMenuTarget)
    this.navbarTarget.classList.toggle('navbar-blackout')
  }

  toggleMenu(target) {
    target.classList.toggle('is-active');
  }
}
