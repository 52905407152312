import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['leftSlider', 'rightSlider', 'input']

  connect() {
    this.element[this.identifier] = this
    if (this.inputTarget.value != 50) {
      this.updateRange()
    }
  }

  updateRange() {
    if (this.inputTarget.value <= 50) {
      this.leftSliderTarget.style.width = `${(50 - this.inputTarget.value)}%`
      this.rightSliderTarget.style.width = '0'
    } else {
      this.leftSliderTarget.style.width = '0'
      this.rightSliderTarget.style.width = `${(this.inputTarget.value - 50)}%`
    }
  }

  clearSliders() {
    this.leftSliderTarget.style.width = '0'
    this.rightSliderTarget.style.width = '0'
    this.inputTarget.value = 50
  }
}
