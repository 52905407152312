import { Controller } from "@hotwired/stimulus"
import _ from "lodash"

export default class extends Controller {
  static targets = ["moodWheelForm", "moodInput"]

  initialize() {
    this.element[this.identifier] = this
    this.element.controller = this
    this.playRecording = this.playRecording.bind(this)
    this.handleMoodChange = _.debounce(this.handleMoodChange.bind(this), 500)
    this.currentMood = null
  }

  handleMoodChange(e) {
    const {id, h, s } = e.detail
    fetch(`/mood_recordings/${id}-${h}-${s}`)
      .then((rsp) => rsp.json())
      .then((json) => this.playRecording(json))
  }

  playRecording(payload) {

     this.currentMood = payload.mood_id
    const playerEvent = new CustomEvent("player:play-pause", {
      detail: {
        controller: {
          ...payload,
          hasNextTrack: false,
          hasPrevTrack: false,
          setPlayer: () => {},
          removePlayer: () => {},
        },
      },
    })

    document.dispatchEvent(playerEvent)
  }

  addMood() {
    if (this.currentMood) {
      this.moodInputTarget.value = this.currentMood
    }
  }

  connect() {
    document.addEventListener(
      `${this.identifier}:mood-change`,
      this.handleMoodChange
    )
  }

  disconnect() {
    document.removeEventListener(
      `${this.identifier}:mood-change`,
      this.handleMoodChange
    )
  }
}
