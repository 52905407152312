import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    content: String,
    copiedMessage: {
      type: String,
      default: "Copied!"
    }
  }

  connect() {
    this.originalContent = this.element.innerHTML
  }

  copy(event) {
    event.preventDefault()

    navigator.clipboard.writeText(this.contentValue).then(() => this.copied())
  }

  copied() {
    this.element.innerHTML = this.copiedMessageValue

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.element.innerHTML = this.originalContent
    }, 2000)
  }
}
