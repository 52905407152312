import WaveSurfer from "wavesurfer.js"
import webAudioTouchUnlock from "web-audio-touch-unlock"

const audioContext = new AudioContext()

const wave = (target) => {
  webAudioTouchUnlock(audioContext)

  return WaveSurfer.create({
    audioContext: audioContext,
    container: target,
    waveColor: "#ffffff54",
    backend: "MediaElement",
    height: 24,
    progressColor: "#FFFFFF",
    barWidth: 3,
    barHeight: 1,
    barGap: 6,
    barRadius: 3,
    cursorWidth: 0,
    responsive: true,
    hideScrollbar: true,
  })
}

export default wave
