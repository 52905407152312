import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import sample from "lodash/sample";

export default class extends Controller {
   static targets = [
      "wrapper",
      "closeIcon",
      "searchInput",
      "searchTypeBtn",
      "searchTypeInput",
      "similarityExamples",
      "spotifyExamples",
      "searchIcon",
      "spotifyIcon",
      "filters",
      "results",
   ];

   static values = {
      animatePlaceholder: { type: Boolean, default: false },
      autoFocusInput: { type: Boolean, default: false },
      isDisabledSpotify: { type: Boolean, default: true }, // this value is used to blocks spotify functionality for animatePlaceholder()
   };

   initialize() {
      this.placeholderValues = {
         similarity: [],
         spotify: ["Search for similarity to a song or artist from Spotify"],
      };
      this.preparePlaceholderValues();
   }

   connect() {
      useClickOutside(this);
      if (this.animatePlaceholderValue) {
         this.animatePlaceholder();
      }

      if (this.searchTypeInputTarget.value) {
         this.ensureCorrectIcon(this.searchTypeInputTarget.value);
      }

      this.toggleCloseIcon();

      this.searchInputTarget.addEventListener("input", this.toggleCloseIcon.bind(this));
   }

   showDropdown() {
      this.wrapperTarget.classList.add("is-active");
      this.showActiveTab(this.searchTypeInputTarget.value);
      if (this.refreshInterval) {
         clearInterval(this.refreshInterval);
      }

      this.toggleCloseIcon();
   }

   hideDropdown() {
      this.toggleCloseIcon();
      this.searchInputTarget.blur();
      this.wrapperTarget.classList.remove("is-active");
      this.similarityExamplesTarget.classList.remove("is-active");
      this.spotifyExamplesTarget.classList.remove("is-active");
   }

   clickOutside() {
      this.hideDropdown();
   }

   toggleCloseIcon() {
      if (this.searchInputTarget.value.trim() === "") {
         this.closeIconTarget.style.display = "none";
         return;
      }

      this.closeIconTarget.style.display = "block";

      this.closeIconTarget.classList.add("is-active");
   }

   searchTypeClicked(event) {
      this.changeSearchType(event.target.dataset["searchType"]);
   }

   ensureCorrectIcon(searchType) {
      if (searchType == "spotify") {
         this.showSpotifyIcon();
      } else {
         this.showSearchIcon();
      }
   }

   changeSearchType(newSearchType) {
      this.ensureCorrectIcon(newSearchType);
      this.resetActiveStates();
      this.showActiveTab(newSearchType);
      this.searchTypeInputTarget.value = newSearchType;
      if (this.autoFocusInputValue) {
         this.searchInputTarget.focus();
      }
   }

   showActiveExamples(searchType) {
      const examples = Array.from(
         document.querySelectorAll("[data-examples-type]")
      );

      examples.forEach((example) => {
         if (
            example.dataset["examplesType"] === searchType &&
            example.children.length > 0
         ) {
            Array.from(example.children).map((e) => e.classList.add("is-active"));
            example.classList.add("is-active");
         } else {
            example.classList.remove("is-active");
         }
      });

      examples.some((example) => example.classList.contains("is-active"))
         ? this.filtersTarget.classList.add("is-active")
         : this.filtersTarget.classList.remove("is-active");
   }

   showActiveTab(searchType) {
      const currentTabFilter = this.searchTypeBtnTargets.find(
         (filter) => filter.dataset["searchType"] == searchType
      );

      if (this.wrapperTarget.classList.contains("is-active")) {
         currentTabFilter.classList.add("is-active");
         this.showActiveExamples(searchType);
      } else {
         this.filtersTarget.classList.remove("is-active");
      }

      this.changeFilterPlaceholder(searchType);
   }

   changeFilterPlaceholder(searchType) {
      if (searchType == "title") {
         this.searchInputTarget.placeholder = "Search by Title";
      } else if (searchType == "keywords") {
         this.searchInputTarget.placeholder =
            "Search by Search human-tagged keywords";
      } else if (searchType == "spotify") {
         this.searchInputTarget.placeholder =
            "Search for similarity to a song or artist from Spotify";
      } else {
         this.searchInputTarget.placeholder =
            "Search for similarity to a free description of a song";
      }
   }

   resetActiveStates() {
      this.searchTypeBtnTargets.forEach((node) =>
         node.classList.remove("is-active")
      );
      Array.from(this.similarityExamplesTargets[0].children).forEach((node) => {
         node.classList.remove("is-active");
      });

      Array.from(this.spotifyExamplesTargets[0].children).forEach((node) => {
         node.classList.remove("is-active");
      });
   }

   changeSearchInputValue(event) {
      this.searchInputTarget.value = event.target.innerText;
   }

   showSpotifyIcon() {
      this.spotifyIconTarget.classList.add("search-bar__spotify--is-active");
      this.searchIconTarget.classList.remove(
         "search-bar__search-input-icon--is-active"
      );
      this.searchInputTarget.classList.add("search-bar--spotify-input");
   }

   showSearchIcon() {
      this.spotifyIconTarget.classList.remove("search-bar__spotify--is-active");
      this.searchIconTarget.classList.add(
         "search-bar__search-input-icon--is-active"
      );
      this.searchInputTarget.classList.remove("search-bar--spotify-input");
   }

   animatePlaceholder() {
      if (this.refreshInterval) {
         return;
      }

      this.similarityExamplesCounter = 0;

      this.refreshInterval = setInterval(() => {
         // !this.isDisabledSpotifyValue is used to block carousel in the animation for spotify, to active it remove this argument from the condition
         if (
            !this.isDisabledSpotifyValue &&
            this.searchTypeInputTarget.value == "similarity" &&
            this.placeholderValues["similarity"].length ==
            this.similarityExamplesCounter
         ) {
            this.changeSearchType("spotify");
            this.searchInputTarget.placeholder = sample(
               this.placeholderValues["spotify"]
            );
         } else {
            if (
               this.placeholderValues["similarity"].length ==
               this.similarityExamplesCounter
            ) {
               this.similarityExamplesCounter = 0;
            }
            this.changeSearchType("similarity");
            this.searchInputTarget.value =
               this.placeholderValues["similarity"][this.similarityExamplesCounter];
            this.similarityExamplesCounter++;
         }
      }, 2000);
   }

   preparePlaceholderValues() {
      Array.from(this.similarityExamplesTarget.children).forEach((node) => {
         this.placeholderValues["similarity"].push(node.innerText);
      });
   }
}
