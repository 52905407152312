import { Controller } from "@hotwired/stimulus"
import { capitalize } from "lodash"
import noUiSlider from "nouislider"

const offValue = 50
const activeLabelClass = "range-input__active-label"
const qualitiesLabelToTag = {
  brightness: ["Dark", "Bright"],
  movement: ["Fluid", "Angular"],
  pacing: ["Regular", "Irregular"],
  density: ["Minimal", "Complex"],
  temperature: ["Cold", "Warm"],
  weight: ["Heavy", "Light"],
}

export default class extends Controller {
  static targets = ["ui", "input", "leftLabel", "rightLabel"]

  pillLabel() {
    const shiftedValue = Math.abs(this.value() - offValue)
    const value = Math.round((shiftedValue / offValue) * 100)
    let label

    if (value === 0 && this.hasValue()) {
      return `${capitalize(this.label())} neutral`
    }

    if (this.value() < offValue) {
      label = qualitiesLabelToTag[this.label()][0]
    } else {
      label = qualitiesLabelToTag[this.label()][1]
    }

    return `${label} ${value}%`
  }

  label() {
    return this.element.querySelector("label").textContent.toLowerCase()
  }

  value() {
    return Math.round(this.element.querySelector("input").value)
  }

  hasValue() {
    return !this.element.classList.contains("inactive")
  }

  initialize() {
    this.element[this.identifier] = this
    this.element.controller = this
  }

  resetValue() {
    this.noUiSlider.set([offValue, offValue])
  }

  setValue(val) {
    const currentVal = Math.round(this.inputTarget.value)
    const nextVal = Math.round(val)

    this.leftLabelTarget.classList.remove(activeLabelClass)
    this.rightLabelTarget.classList.remove(activeLabelClass)

    if (currentVal !== nextVal) {
      if (nextVal > offValue) {
        this.rightLabelTarget.classList.add(activeLabelClass)
      }

      if (nextVal < offValue) {
        this.leftLabelTarget.classList.add(activeLabelClass)
      }

      this.inputTarget.value = nextVal
      const event = new Event("change", {
        bubbles: true,
        cancelable: true,
      })
      this.inputTarget.dispatchEvent(event)
    }
  }

  connect() {
    const initialValue = this.inputTarget.value
    this.noUiSlider = noUiSlider.create(this.uiTarget, {
      start: [offValue, initialValue],
      behaviour: "unconstrained",
      connect: true,
      range: {
        min: 0,
        max: 100,
      },
    })

    this.noUiSlider.on("set", (values) => this.setValue(values[1]))
  }

  disconnect() {
    this.noUiSlider.destroy()
  }
}
