import FilterPanelController from "./filter_panel_controller"
import iro from "@jaames/iro"
import { options } from "../lib/picker"
import { closestMood, moodByTitle } from "../lib/mood"
import { cloneDeep, each, isNil, omitBy } from "lodash"

export default class extends FilterPanelController {
  static targets = ["pills", "template", "picker", "inputDescription"]

  initialize() {
    this.element[this.identifier] = this
    this.element.controller = this
    this.moods = cloneDeep(window.moods)
  }

  resetMood(event) {
    event.preventDefault()
    event.target.closest(".filter__pill").remove()

    this.dispatchMoodTransition()
    this.change()
    this.enumerateDuplicateTitles()
  }

  reset(event) {
    event.preventDefault()
    this.pillsTarget.innerHTML = ""

    this.dispatchMoodTransition()
    this.change()
  }

  hasValues() {
    return this.pillsTarget.querySelectorAll("input").length > 0
  }

  selectedValuesAsText() {
    const selections = []

    this.pillsTarget
      .querySelectorAll(".filter__pill__badge")
      .forEach((target) => {
        const style = target.getAttribute("style")
        const moodBadge = `<span class="mood-badge" style="${style}"></span>`
        selections.push(moodBadge)
      })

    return selections.join("")
  }

  addPresetMoods(event) {
    this.reset(event)

    const presetTarget = event.target.closest(".mood-palette-row")

    const moods = omitBy(
      [].slice
        .call(presetTarget.querySelectorAll(".palette-square"))
        .map((sq) => moodByTitle(this.moods, sq.dataset.moodTitle)),
      isNil
    )

    each(moods, (mood) => {
      const hsl = { h: mood.h, s: mood.s, l: 50 }
      const title = mood.title
      this.insertPill(hsl, title)
    })

    this.dispatchMoodTransition()
    this.change()
  }

  insertPill(hsl, title) {
    const content = this.templateTarget.content
    content.querySelector('input[name="filter[moods][][degree]"]').value = hsl.h
    content.querySelector('input[name="filter[moods][][distance]"]').value =
      hsl.s
    content.querySelector(".filter__pill__title").innerText = title

    const badgeColor = `hsla(${hsl.h}, ${hsl.s}%, 50%, 1)`
    content.querySelector(".filter__pill__badge").style.backgroundColor =
      badgeColor

    const clone = document.importNode(this.templateTarget.content, true)
    this.pillsTarget.appendChild(clone)

    const node = this.pillsTarget.lastElementChild
    node.classList.add(`filter__pill--${hsl.h}-${hsl.s}`)
    node.setAttribute("data-hsl", JSON.stringify(hsl))
    this.enumerateDuplicateTitles()
  }

  enumerateDuplicateTitles() {
    if (this.hasValues()) {
      const pills = this.pillsTarget.querySelectorAll(".filter__pill__title")
      const pillsText = [].slice.call(pills).map((e) => e.innerText)
      const { duplicated } = pillsText.reduce(
        (acc, curr, i) => {
          const { seen, duplicated } = acc
          if (seen[curr] === undefined) {
            seen[curr] = 0
          } else {
            seen[curr] += 1
          }
          duplicated[i] = seen[curr]
          return {
            seen,
            duplicated,
          }
        },
        { seen: {}, duplicated: {} }
      )
      each(pills, (pill, i) => {
        const isDuplicate = duplicated[i]
        if (isDuplicate > 0) {
          pill.dataset.count = isDuplicate + 1
          pill.classList.add("duplicate")
        } else {
          pill.classList.remove("duplicate")
        }
      })
    }
  }

  addMood(event) {
    event.preventDefault()

    const hsl = this.colorPicker.color.hsl
    const title = this.inputDescriptionTarget.innerText

    const needsPill =
      !!this.pillsTarget &&
      !this.pillsTarget.querySelector(`.filter__pill--${hsl.h}-${hsl.s}`)

    if (needsPill) {
      this.insertPill(hsl, title)
    }

    this.dispatchMoodTransition()
    this.change()
  }

  dispatchMoodTransition() {
    const selectedMoods = Array.from(
      this.pillsTarget.querySelectorAll(".filter__pill")
    ).map((element) => {
      return JSON.parse(element.dataset.hsl)
    })
    const from = selectedMoods[0]
    const to = selectedMoods[selectedMoods.length - 1]

    if (from && to) {
      const event = new CustomEvent("background-color:change", {
        detail: { from, to },
      })

      document.dispatchEvent(event)
    }
  }

  dispatchMoodSet() {
    const selectedMoods = Array.from(
      this.pillsTarget.querySelectorAll(".filter__pill")
    ).map((element) => {
      return JSON.parse(element.dataset.hsl)
    })
    const from = selectedMoods[0]
    const to = selectedMoods[selectedMoods.length - 1]

    if (from && to) {
      const event = new CustomEvent("background-color:set", {
        detail: { from, to },
      })

      document.dispatchEvent(event)
    }
  }

  connect() {
    const iroOptions = {
      ...options,
      layout: [
        {
          component: iro.ui.Wheel,
        },
      ],
    }

    if (!this.colorPicker) {
      this.colorPicker = new iro.ColorPicker(this.pickerTarget, iroOptions)
      this.colorPicker.on("input:change", () => {
        const hsl = this.colorPicker.color.hsl
        const closest = closestMood(this.moods, hsl)

        this.inputDescriptionTarget.innerText = closest.title
      })

      const { degree, distance } = JSON.parse(this.element.dataset.initialMood)
      const l = this.colorPicker.color.hsl.l
      this.colorPicker.color.hsl = { h: degree, s: distance, l }
    }
    this.dispatchMoodSet()
    this.enumerateDuplicateTitles()
  }
}
