import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["actionButtons", "advert", "details"]

  detailsTargetConnected() {
    this.checkAdvertStatus()
  }

  checkAdvertStatus() {
    if (!this.hasActionButtonsTarget) {
      return;
    }

    this.projectId = this.detailsTarget.dataset['projectId']
    this.projectEditable = this.detailsTarget.dataset['projectEditable']
    if (this.projectEditable && document.URL.includes(`projects/${this.projectId}`)) {
      this.showAdvert()
    } else {
      this.hideAdvert()
    }
  }

  showAdvert() {
    this.actionButtonsTarget.classList.add('is-hidden')
    this.detailsTarget.classList.add('is-hidden')
    this.advertTarget.classList.remove('is-hidden')
  }

  hideAdvert() {
    this.actionButtonsTarget.classList.remove('is-hidden')
    this.detailsTarget.classList.remove('is-hidden')
    this.advertTarget.classList.add('is-hidden')
  }
}
