document.addEventListener("turbo:submit-start", function(e) {
  const submitBtn = e.target.querySelector('button[type=submit]')
  if (submitBtn) {
    submitBtn.disabled = true
    submitBtn.classList.add('remote-loading')
  }
})

document.addEventListener("turbo:submit-end", function() {
  document.querySelectorAll('button.remote-loading').forEach(element => {
    element.classList.remove('remote-loading')
    element.disabled = false
  })
})

document.addEventListener("turbo:click", function(e) {
  e.target.classList.add('remote-loading')
})

document.addEventListener("turbo:frame-load", function() {
  document.querySelectorAll('.remote-loading').forEach(element => {
    element.classList.remove('remote-loading')
  })
})

document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response } } = event;
  event.preventDefault();
  Turbo.visit(response.url);
});

document.addEventListener("turbo:before-cache", () => {
  document.querySelectorAll('.tracklist-item__title.active').forEach(element => {
    element.classList.remove('active')
  })
})
