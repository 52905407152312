import { Datepicker } from 'stimulus-datepicker'

export default class extends Datepicker {
  gotoPrevMonth(event) {
    event.stopPropagation()
    super.gotoPrevMonth()
  }

  gotoNextMonth(event) {
    event.stopPropagation()
    super.gotoNextMonth()
  }

  gotoToday(event) {
    event.stopPropagation()
    super.gotoToday()
  }
}
