import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
   static targets = ["wave"];

   connect() {
      this.handleWindowResize();
   }

   handleWindowResize() {
      const parentWave = document.querySelector(".landing-search__header");

      if (parentWave) {
         this.updateLanderWave(parentWave);
         return;
      }

      this.update404Wave();
   }

   updateLanderWave(parentWave) {
      const parentWaveBottom = parentWave.getBoundingClientRect().bottom;
      this.waveTarget.style.top = `${parentWaveBottom + 50}px`;
   }

   update404Wave() {
      const parentWave = document.querySelector(".notfound__content");

      if (!parentWave) {
         return;
      }

      const parentWaveTop = parentWave.getBoundingClientRect().top;

      if (window.innerWidth > 2600) {
         this.waveTarget.style.top = `${parentWaveTop - 200}px`;
         return;
      }

      if (window.innerWidth > 1600) {
         this.waveTarget.style.top = `${parentWaveTop - 10}px`;
         return;
      }

      this.waveTarget.style.top = `${parentWaveTop + 50}px`;
   }
}
