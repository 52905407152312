import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['vocals', 'noVocals']

  manageVocals(event) {
    if (event.target == this.noVocalsTarget) {
      this.vocalsTargets.forEach((vocal) => {
        vocal.checked = false 
      })
    } else {
      this.noVocalsTarget.checked = false
    }
  }
}
