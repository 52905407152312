import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
   static targets = ["tooltip", "button"];
   static values = {
      recordingId: Number,
   };

   updateButtonState() {
      const userData = this.getUserData();

      if (this.hasDownloadedRecording(userData)) {
         this.tooltipTarget.innerHTML = "Download Again";
         return;
      }

      if (userData.userRemainingDownloads > 0) {
         this.tooltipTarget.innerHTML = `${userData.userRemainingDownloads} Download${userData.userRemainingDownloads > 1 ? "s" : ""} remaining`;
         return;
      }

      this.disableButton(userData);
   }

   disableButton(userData) {
      // span the the button is already disabled
      if (this.buttonTarget.tagName === "SPAN") return;

      this.buttonTarget.disabled = true;
      this.buttonTarget.removeAttribute("data-action");

      const icon = this.buttonTarget.querySelector(".icon");
      icon.classList.add("icon--not-allowed");

      if (!userData.userAllowedToRequestMoreDownloads) {
         this.tooltipTarget.innerHTML = `Downloads request pending`;
         return;
      }

      this.tooltipTarget.innerHTML = "Request downloads in the three-dot menu";
   }

   getUserData() {
      const userDataEl = document.querySelector("#user_data");
      if (!userDataEl) return null;

      return userDataEl["user-data"];
   }

   hasDownloadedRecording(userData) {
      return userData.userDownloadedRecordings.includes(this.recordingIdValue);
   }
}
