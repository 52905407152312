import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    initial: Boolean,
    recordingId: Number
  }

  static targets = ['add', 'remove']

  update(event) {
    const userData = event.srcElement['user-data']
    if (userData.activeProjectRecordings.includes(this.recordingIdValue)) {
      this.showRemoveBtn()
    } else {
      this.showAddBtn()
    }
    this.handleEditability(userData.activeProjectEditable)
    this.handleEditability(userData.activeProjectEditable)
  }

  showAddBtn() {
    this.addTarget.classList.remove('is-hidden')
    this.removeTarget.classList.add('is-hidden')
  }

  showRemoveBtn() {
    this.removeTarget.classList.remove('is-hidden')
    this.addTarget.classList.add('is-hidden')
  }

  handleEditability(editable) {
    if (editable) {
      this.addTarget.disabled = false
      this.removeTarget.disabled = false
    } else {
      this.addTarget.classList.add('is-hidden')
      this.removeTarget.disabled = true
    }
  }
}
