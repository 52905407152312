import { Controller } from "@hotwired/stimulus"
import TWEEN from "@tweenjs/tween.js"

export default class extends Controller {
  initialize() {
    this.element[this.identifier] = this
    this.element.controller = this

    this.gradientColor = [
      JSON.parse(this.element.dataset.from),
      JSON.parse(this.element.dataset.to),
    ]

    this.tweenGroup = new TWEEN.Group()

    const [from, to] = this.gradientColor
    this.handleGradientChange = this.handleGradientChange.bind(this)
    this.handleGradientSet = this.handleGradientSet.bind(this)
    this.setGradient(from, to)
    this.animate = this.animate.bind(this)
  }

  handleGradientChange(event) {
    event.preventDefault()
    const { to: nextTo, from: nextFrom } = event.detail

    const prevFrom = this.gradientColor[0]
    const prevTo = this.gradientColor[1]

    const colorTransition = {
      fromHue: prevFrom.h,
      fromSat: prevFrom.s,
      fromLit: prevFrom.l,
      toHue: prevTo.h,
      toSat: prevTo.s,
      toLit: prevTo.l,
    }

    const endTransition = {
      fromHue: nextFrom.h,
      fromSat: nextFrom.s,
      fromLit: nextFrom.l,
      toHue: nextTo.h,
      toSat: nextTo.s,
      toLit: nextTo.l,
    }

    this.tween = new TWEEN.Tween(colorTransition, this.tweenGroup)
      .to(endTransition)
      .start()
      .onUpdate(() => {
        const from = {
          h: colorTransition.fromHue,
          s: colorTransition.fromSat,
          l: colorTransition.fromLit,
        }

        const to = {
          h: colorTransition.toHue,
          s: colorTransition.toSat,
          l: colorTransition.toLit,
        }

        this.gradientColor = [from, to]
        this.setGradient(from, to)
      })
  }

  pushColor(color) {
    if (this.tween) {
      this.tween.stop()
    }

    const [prevFrom, prevTo] = this.gradientColor
    const nextFrom = prevTo
    const nextTo = color
  }

  handleGradientSet(event) {
    event.preventDefault()
    const { to, from } = event.detail

    this.gradientColor[0] = from
    this.gradientColor[1] = to

    this.setGradient(from, to)
  }

  setGradient(from, to) {
    const gradient = `
      linear-gradient(to top, hsl(${from.h}, ${from.s}%, ${from.l}%) , hsl(${to.h}, ${to.s}%, ${to.l}%) )
    `
    this.element.style.background = gradient
  }

  animate() {
    this.animationFrame = requestAnimationFrame(this.animate)
    this.tweenGroup.update()
  }

  connect() {
    document.addEventListener(
      `${this.identifier}:change`,
      this.handleGradientChange
    )
    document.addEventListener(`${this.identifier}:set`, this.handleGradientSet)
    this.animate()
  }

  disconnect() {
    cancelAnimationFrame(this.animationFrame)
    document.removeEventListener(
      `${this.identifier}:change`,
      this.handleGradientChange
    )
    document.removeEventListener(
      `${this.identifier}:set`,
      this.handleGradientSet
    )
  }
}
