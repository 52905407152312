import Carousel from 'stimulus-carousel'

export default class extends Carousel {
  get defaultOptions() {
    return {
      navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
      pagination: { el: '.swiper-pagination' }
    }
  }
}
