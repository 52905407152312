import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  initialize() {
    this.element[this.identifier] = this
    this.element.controller = this
  }

  pillLabel() {
    return this.label()
  }

  label() {
    return this.element.querySelector("label").textContent
  }

  value() {
    return Math.round(this.element.querySelector("input").value)
  }

  hasValue() {
    return this.inputTarget.checked
  }

  resetValue() {
    this.inputTarget.checked = false

    const event = new Event("change", {
      bubbles: true,
      cancelable: true,
    })
    this.inputTarget.dispatchEvent(event)
  }
}
