import FilterPanelController from "./filter_panel_controller"
import { find, intersection, union, without, xor } from "lodash"

const ALLOWED_QUALITIES = [
  "temperature",
  "weight",
  "brightness",
  "pacing",
  "movement",
  "density",
]

export default class extends FilterPanelController {
  static targets = ["selectedQualities", "control"]

  connect() {
    this.selectedQualities = intersection(
      this.selectedQualitiesTarget.value.split(","),
      ALLOWED_QUALITIES
    )
    this.setUnusedInactive()
  }

  setUnusedInactive() {
    this.controlTargets.forEach((target) => {
      if (this.selectedQualities.indexOf(target.dataset.quality) > -1) {
        target.classList.remove("inactive")
      } else {
        target.classList.add("inactive")
      }
    })
  }

  activateQuality(event) {
    if (event.target.type !== "range") return
    const nextQualities = union(this.selectedQualities, [
      event.target.parentElement.dataset.quality,
    ])
    this.updateQualities(nextQualities)
  }

  handleToggle(event) {
    this.toggleQualities([event.params.quality])
  }

  toggleQualities(qualities) {
    const nextQualities = xor(this.selectedQualities, qualities)
    const removedQualities = intersection(this.selectedQualities, qualities)
    removedQualities.forEach((quality) => {
      const target = find(
        this.controlTargets,
        (target) => target.dataset.quality === quality
      )
      target.controller.resetValue()
    })
    this.updateQualities(nextQualities)
    this.setUnusedInactive()
  }

  updateQualities(nextQualities) {
    this.selectedQualitiesTarget.value = nextQualities.join(",")
    this.selectedQualities = intersection(nextQualities, ALLOWED_QUALITIES)
    this.setUnusedInactive()
    const change = new Event("change", {
      bubbles: true,
      cancelable: true,
    })
    this.selectedQualitiesTarget.dispatchEvent(change)
  }

  reset(event) {
    event.preventDefault()
    event.stopPropagation()

    this.toggleQualities(this.selectedQualities)
  }
}
