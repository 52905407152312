import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'leftSlider', 'rightSlider', 'resetIcon']

  connect() {
    if (this.inputTargets.filter(input => input.value != 50).length > 0) { 
      this.resetIconTarget.classList.add('icon__reset-qualities--active')
    } else {
      this.resetIconTarget.classList.remove('icon__reset-qualities--active')
    }
  }

  clearFilters() {
    this.inputTargets.forEach( input => input.value = 50)
    this.leftSliderTargets.forEach( input => input.style.width = '0')
    this.rightSliderTargets.forEach( input => input.style.width = '0')
    this.resetIconTarget.classList.remove('icon__reset-qualities--active')
  }

  manageResetIcon() {
    if (this.inputTargets.filter(input => input.value != 50).length > 0) { 
      this.resetIconTarget.classList.add('icon__reset-qualities--active')
    } else {
      this.resetIconTarget.classList.remove('icon__reset-qualities--active')
    }
  }
}
