import { Controller } from "@hotwired/stimulus"

const ENTER_KEY_CODE = 13

export default class extends Controller {
  static targets = ["input", "clear"]

  initialize() {
    this.element[this.identifier] = this
    this.element.controller = this
  }

  handleEnter(e) {
    if (e.keyCode === ENTER_KEY_CODE) {
      e.preventDefault()
      const event = new Event("change", {
        bubbles: true,
        cancelable: true,
      })

      this.element.dispatchEvent(event)
    }
  }

  handleChange(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  handleInput(e) {
    this.showOrHideClearBtn()
    const event = new Event("change", {
      bubbles: true,
      cancelable: true,
    })

    this.element.dispatchEvent(event)
  }

  showOrHideClearBtn() {
    if (this.inputTarget.value === "") {
      this.clearTarget.classList.remove("is-active")
    } else {
      this.clearTarget.classList.add("is-active")
    }
  }

  clearInput(e) {
    e.preventDefault()
    this.inputTarget.value = ""
    this.handleInput(e)
  }

  connect() {
    this.showOrHideClearBtn()
  }
}
