/** NOTE THIS FILE IS DEPRECATED AND WILL BE REMOVED IN THE FUTURE **/
import { Controller } from "@hotwired/stimulus";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import TWEEN from "@tweenjs/tween.js";
import _ from "lodash";
import iro from "@jaames/iro";
import { closestMood } from "../lib/mood";

export default class extends Controller {
   static targets = [
      "render",
      "label",
      "stepIntro",
      "stepDescription",
      "nextLabel",
      "currentLabel",
      "controlButton",
   ];

   initialize() {
      this.element[this.identifier] = this;
      this.element.controller = this;
      this.animate = this.animate.bind(this);
      this.start = this.start.bind(this);
      this.prevMood = null;
      this.handleMood = _.throttle(this.handleMood.bind(this), 80);
      this.handleWindowResize = this.handleWindowResize.bind(this);
      this.iro = new iro.Color();
      this.showRipple = this.showRipple.bind(this);
      this.hideRipple = this.hideRipple.bind(this);
      this.materials = [];
      document.addEventListener("player:is-playing", this.showRipple);
      document.addEventListener("player:is-pausing", this.hideRipple);
   }

   showRipple() {
      const rippleId = this.element.dataset.rippleId;
      const rippleTarget = document.getElementById(rippleId);
      rippleTarget.classList.add("is-active");
   }

   hideRipple() {
      const rippleId = this.element.dataset.rippleId;
      const rippleTarget = document.getElementById(rippleId);
      rippleTarget.classList.remove("is-active");
   }

   handleWindowResize() {
      const width = this.element.clientWidth;
      const height = this.element.clientHeight;

      const aspect = width / height;

      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(width, height);
      this.renderTex.setSize(width * 0.25, height * 0.25);

      this.camera.aspect = aspect;
      this.camera.updateProjectionMatrix();

      if (this.controls.enabled) {
         this.controls.handleResize();
      }
   }

   hexToHSL(hex) {
      this.iro.hexString = hex;
      return this.iro.hsl;
   }

   createScene() {
      const aspect = this.element.clientWidth / this.element.clientHeight;
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(10, aspect, 0.1, 1000);

      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      this.renderer.setSize(this.element.clientWidth, this.element.clientHeight);
      this.renderTarget.appendChild(this.renderer.domElement);
      this.renderTex = new THREE.WebGLRenderTarget(
         this.element.clientWidth,
         this.element.clientHeight,
         {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.NearestFilter,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
         }
      );

      this.materials.push(this.renderTex);
   }

   setInitialCameraPosition() {
      this.camera.position.z = 10;
      this.camera.position.y = 3.4;
      this.camera.position.x = 0;
   }

   createControls() {
      this.controls = new TrackballControls(
         this.camera,
         this.renderer.domElement
      );
      this.controls.rotateSpeed = 3.0;
      this.controls.zoomSpeed = 0.0;
      this.controls.panSpeed = 0.0;
      this.controls.enabled = false;
      this.controls.addEventListener("change", () => this.handleControlChange());
   }

   start(event) {
      event.preventDefault();
      this.stepIntroTarget.classList.add("is-out");

      const time = 1000;
      const fov = { val: this.camera.fov };

      new TWEEN.Tween(this.camera.position)
         .to({ x: 0, y: 0, z: 15 }, time)
         .easing(TWEEN.Easing.Quadratic.In)
         .start()
         .onComplete(() => {
            this.controls.enabled = true;
            this.stepDescriptionTarget.classList.add("is-in");
         });

      new TWEEN.Tween(fov)
         .to({ val: 45 }, time)
         .onUpdate(() => {
            this.camera.fov = fov.val;
            this.camera.updateProjectionMatrix();
         })
         .start();
   }

   nextText(text) {
      const time = 80;
      let style = { top: 50, opacity: 1 };

      new TWEEN.Tween(style)
         .to({ top: 47, opacity: 0 }, time)
         .start()
         .onUpdate(() => {
            this.currentLabelTarget.style.top = `${style.top}%`;
            this.currentLabelTarget.style.opacity = style.opacity;
         });

      this.nextLabelTarget.innerHTML = text;
      let nStyle = { top: 53, opacity: 0 };
      new TWEEN.Tween(nStyle)
         .to({ top: 50, opacity: 1 }, time)
         .start()
         .onUpdate(() => {
            this.nextLabelTarget.style.top = `${nStyle.top}%`;
            this.nextLabelTarget.style.opacity = nStyle.opacity;
         })
         .onComplete(() => {
            this.currentLabelTarget.style.top = "50%";
            this.currentLabelTarget.style.opacity = 1;
            this.currentLabelTarget.innerHTML = text;
            this.nextLabelTarget.style.top = "53%";
            this.nextLabelTarget.style.opacity = 0;
         });
   }

   addShapes() {
      // http://stemkoski.github.io/Three.js/Simple-Glow.html
      const shadTex = new THREE.TextureLoader().load(SphereImages["shadow"]);

      const shadMat = new THREE.SpriteMaterial({ map: shadTex, color: 0xffffff });
      const sphereShad = new THREE.Sprite(shadMat);
      sphereShad.scale.set(10, 10, 1);
      this.scene.add(sphereShad);

      const sphereTex = new THREE.TextureLoader().load(SphereImages["gradient"]);
      const geometry = new THREE.SphereGeometry(3, 64, 64);
      const mesh = new THREE.MeshBasicMaterial({
         color: 0xffffff,
         map: sphereTex,
      });

      this.materials.push(sphereTex);
      this.materials.push(geometry);

      const sphere = new THREE.Mesh(geometry, mesh);
      this.scene.add(sphere);
   }

   handleControlChange() {
      // this is to delay the setting of the first mood to show
      // the "every mood..." copy
      if (this.showForm) {
         this.renderer.setRenderTarget(this.renderTex);
         this.renderer.clear();
         this.renderer.render(this.scene, this.camera);

         const rb = new Float32Array(4);
         this.renderer.readRenderTargetPixels(
            this.renderTex,
            this.renderTex.width * 0.5,
            this.renderTex.height * 0.5,
            1,
            1,
            rb
         );

         this.renderer.setRenderTarget(null);
         this.renderer.clear();
         this.renderer.render(this.scene, this.camera);

         const targetCol = new THREE.Color(rb[0], rb[1], rb[2]);
         const hsl = this.hexToHSL(`#${targetCol.getHexString()}`);

         const event = new CustomEvent("background-color:set", {
            detail: { from: hsl, to: hsl },
         });

         document.dispatchEvent(event);
         this.handleMood(hsl);
         this.controlButtonTargets.forEach((btn) => {
            btn.classList.add("is-in");
         });
      }

      this.showForm = true;
   }

   handleMood(hsl) {
      const nextMood = closestMood(window.moods, hsl);

      if (this.prevMoodString !== nextMood.title) {
         this.nextText(nextMood.title);
         this.currentMood = nextMood.source;

         const changeEvent = new CustomEvent("mood-form:mood-change", {
            detail: nextMood.source,
         });

         document.dispatchEvent(changeEvent);

         this.prevMoodString = nextMood.title;
      }
   }

   animate() {
      this.animationFrame = requestAnimationFrame(this.animate);
      TWEEN.update();
      if (this.controls.enabled) {
         this.controls.update();
      }

      this.prevCamPos = this.camera.position;
      this.renderer.clear();
      this.renderer.render(this.scene, this.camera);
   }

   connect() {
      window.addEventListener("resize", this.handleWindowResize, false);
      this.materials = [];
      this.createScene();
      this.createControls();
      this.addShapes();
      this.setInitialCameraPosition();
      this.animate();
      this.start();
   }

   disconnect() {
      window.removeEventListener("resize", this.handleWindowResize, false);
      cancelAnimationFrame(this.animationFrame);
      this.materials.forEach((obj) => obj.dispose());
      this.materials = [];
      this.scene.clear();
      document.removeEventListener("player:is-playing", this.showRipple);
      document.removeEventListener("player:is-pausing", this.hideRipple);
   }
}
