import { throttle } from "lodash"
import wave from "./wave"

const VOLUME_FLOOR = 0.045

const round = (num) => Math.round((num + Number.EPSILON) * 10000) / 10000

let filters = []

// Values from [0...100]
const LOG_VALS = [...Array(101).keys()]
  // Every valid slider position [0.01...1.00]
  .map((i) => round(i * 0.01))
  /**
   * Cache the slider positions to their exponentiated equivalent
   *
   * {
   *   0: 0
   *   0.01: 0.045
   *   0.1: 0.0451
   *   ...
   *   0.98: 0.9682000000000001
   *   0.99: 1
   *   1: 1
   * }
   */

  .reduce((dict, k) => {
    dict[k.toString()] =
      k === 0 ? k : Math.min(1, round(k ** (4 - VOLUME_FLOOR)) + VOLUME_FLOOR)
    return dict
  }, {})

export const commitVolume = (wave, targetPosition) =>
  wave.setVolume(LOG_VALS[round(targetPosition)])

const handleVolumeTargetChange = (
  wave,
  targetPosition,
  callback = () => {}
) => {
  commitVolume(wave, targetPosition)
  callback()
}

export const setVolume = throttle(handleVolumeTargetChange, 80)
